
import newApi from '../new-api';
export const apiServer = {
  getDataByType(type, apiMethod, params, callback) {
    let types = ['spec', 'sds', 'coa']
    if (!types.includes(type)) {
      throw new Error('Invalid type');
    }
    let language = 'EN';
    let pathLanguage = localStorage.getItem('language');
    if(typeof(pathLanguage)!='undefined'){
      language = localStorage.getItem('language').toUpperCase()
    }

    if (typeof callback !== 'function') {
      throw new Error('Callback must be a function');
    }
    if (language == 'ZH') {
      language = 'CN'
    }

    const apiMap = {
      EN: {
        spec: 'enSpecServer',
        sds: 'enSdsServer',
        coa: 'enCoaServer'
      },
      CN: {
        spec: 'cnSpecServer',
        sds: 'cnSdsServer',
        coa: 'cnCoaServer'
      }
    };

    const apiServer = apiMap[language]?.[type];
    // console.info('newApi[apiServer]:',newApi[apiServer])

    if (!apiServer) {
      throw new Error(`Invalid language or type: ${language}, ${type}`);
    }

    console.info('apiServer:', apiServer);
    console.info('apiMethod:', apiMethod);
    const apiCall = newApi[apiServer][apiMethod](params);

    apiCall.then(data => {
      callback(data);
    }).catch(err => {
      console.error('getDataByType ERROR:',err);
    });
  },
  isAdminAuth(username) {
    return !!(
      username === "JamesChen" ||
      username === "admin" ||
      username === "JiaZhang"
    );
  }
}
