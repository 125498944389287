let mappingData = {
    "number":"序号",
    "verify":"审核",
    "rev":"版本",
    "create_by":"创建",
    "create_time":"创建时间",
    "update_by":"编辑",
    "update_time":"编辑时间",
    "verified_by":"审核人",
    "verified_time":"审核时间",
    "action":"操作",
    "edit":"编辑",
    "copy":"复制",
    "delete":"删除",
    "product_type":"产品类型",
    "product_description":"产品描述",
    "list":"中文列表",
    "please_input":"请输入",
    "lot":"批号",
    

    "item_code": "产品编码",
    "sds_type": "SDS类别",
    "product_name": "产品名称",
    "version_number": "版本号",
    "customer_product_name": "客户定制产品名称",
    "botanical_name": "植物学名",
    "inci_name": "INCI名称",
    "appearance": "外观",
    "solubility": "溶解度",
    "ph": "pH",
    "specific_gravity": "比重",
    "color": "颜色",
    "odor": "气味",
    "customs_classification": "海关分类",
    "print_title": "编辑打印标题",
    "supplier_or_producer": "供应商/生产商",
    "storage": "存放",

    "composition_information_on_ingredients": "组份/成分说明",
    "substance_name": "物料名称",
    "cas_number": "CAS编号",
    "einecs_number": "EINECS编号",
    "r_phras": "风险描述语",
    "s_phrase": "安全描述语",

    "revision": "修订记录",
    "approved_by": "批准人",
    "edited_by": "修订人",
    "items_revised": "修订项目",
    "reason": "修订原因",
    "requested_by": "提出修订人",
    "revision_date": "修订日期"
}

// 通过 value 获取 key
export function getKeyByValue(value) {
    for (let key in mappingData) {
        if (mappingData[key] === value) {
            return key;
        }
    }
    return null; // 未找到返回 null
}

// 通过 key 获取 value
export function getValueByKey(key) {
    return mappingData[key] !== undefined ? mappingData[key] : null; // 如果存在返回 value，否则返回 null
}